export default {
  features: {
    addressFromLocalStorageEnabled: true,
    addressValidationInRegistrationEnabled: true,

    agreementsEblokEnabled: false,
    agreementsEmailSmsEnabled: false,
    agreementsGeneralProgramTermsTextEnabled: false,
    agreementsPersonalDataEnabled: true,
    agreementsSmsChannel: ['EMAIL'],
    agreementsStaffinoEnabled: false,
    agreementsThirdPartyMarketingEnabled: false,

    babyClubEnabled: false,
    birthDateEnabled: true,
    birthDateOverEnabled: 18,
    birthDateValidateOnSubmit: true,

    companyDataPrefillEnabled: false,
    companyItInvoicingEnabled: false,

    competitionService: {
      competitionImagePath: '',
    },

    correspondenceEmailEnabled: false,
    customerSupportWhatsappChannelEnabled: false,
    degreesEnabled: false,
    discountEnabled: false,
    downloadInvoiceEnabled: false,

    favoriteFilterCategoriesIds: [
      1222, 634, 788, 821, 932, 1053, 1106, 1132, 1149, 1191, 1200,
    ],

    loyaltyCardBackImagePath: '',
    loyaltyCardCodeLengthCheckEnabled: false,
    loyaltyCardImagePath: '/loyalty-card/general-loyalty-card.jpg',
    loyaltyCardMyProfileImagePath: '/images/loyalty-card/loyalty-card-my-profile-red.svg',
    loyaltyCardV2: false,

    phoneNumberRequired: false,

    priceEnabled: true,
    priceSummaryEnabled: true,
    shippingTaxEnabled: false,
    registrationAddressEnabled: false,
    removeUserEnabled: false,
    sexEnabled: true,
    sexThirdOptionEnabled: true,
    sexRequiredEnabled: false,
    typingInLoginEmailEnabled: false,
    unitPriceEnabled: true,
    ordersFilterFromEshopEnabled: false,

    emailRestrictedDomainEnabled: false,

    surveyEnabled: false,
    userFillableDetails: {
      pregnant: false,
      breastFeeding: false,
      allergies: false,
      treatments: false,
      height: false,
      weight: false,
    },
  },
}
