export default {
  global: {
    agreementsTopic: 'ESHOP',
    city: 'Warszawa',
    country: 'Polska',
    langIso: 'pl-PL',
    loqateApiKey: '',
    pagination: {
      productsPerPage: 24,
    },
    pharmacyTypes: [],
    phoneCode: '+48',
    phoneCodes: ['+48'],
    phoneValidations: ['^[1-9][0-9]{8}$'],
    storeName: 'Dr.Max',
    validationPhone: '^[1-9][0-9]{8}$',
    zipLength: 6,

    /**
     * Global features - used across multiple common modules
     */
    advancedAgreementsEnabled: false,
    billingInformationAddressEnabled: false,
    companyBillingAddressEnabled: true,
    crnEnabled: false,
    loggedPriceInfoEnabled: true,
    loyaltyCardEnabled: false,
    loyaltyCardPlusPrefixEnabled: false,
    loyaltySavingsEnabled: false,
    multiStoreEnabled: true,
    myAccountCouponsEnabled: false,
    myAccountSorpCardCodeToOtpModeEnabled: false,
    myAccountSorpFiscalCodeEnabled: true,
    myAccountSorpEnabled: false,
    myAccountSorpLoyaltyCardNumberDigits: 9,
    myAccountSorpLoyaltyCardNumberStaticPrefix: '++',
    myAccountSorpLoyaltyProgramBenefitsCount: 0,
    myAccountSorpStandAloneCardModeEnabled: false,
    myAccountAmazonEnabled: false,
    myAccountAmazonFromPlacementApi: false,
    myAccountAmazonAnotherOffers: false,
    myAccountOtherOffersButtonEnabled: false,
    newUrlResolverEnabled: true,
    otcFormEnabled: false,
    phoneCodeEnabled: true,
    phoneCodeSelectEnabled: true,
    phoneCodeSentSeparatelyEnabled: true,
    regionSelectEnabled: false,
    savingsAndBenefitsInHeaderEnabled: false,
    skDicEnabled: false,
    splitStreetNumberBeforeSavingEnabled: true,
    stockStatusQuantityCheckEnabled: true,
    streetNumberEnabled: true,
    vatIdEnabled: true,
    vatIdRequired: true,

    /**
     * Google Maps API
     * API Keys: https://mydrmax.atlassian.net/wiki/spaces/GLECOM/pages/1600290889/API+keys+-+Google+Maps
     * Supported languages: Subset of ISO 639-1, see https://developers.google.com/maps/faq#languagesupport
     */
    maps: {
      apiKey: 'AIzaSyDTy7t45Epxc3kWU_khrN6-LBliGsliM6s',
      interfaceLanguage: 'pl',
      myLocationIcon: 'map-my-location.png',
      defaultLocation: {
        latitude: 52.237049,
        longitude: 21.017532,
      },
    },

    watchdog: {
      stockAlertEnabled: false,
      priceAlertEnabled: true,
      forceLoginEnabled: false,
    },
  },
}
